<template>
  <div class="lg:flex lg:space-x-10">
    <div class="lg:px-20 space-y-7 w-full">
      <h1 class="lg:text-3xl text-2xl font-semibold mb-6">Sửa playlist: {{ playlist.title }}</h1>
      <div v-if="message">
        <alert :message="message" :status="status"/>
      </div>
      <form v-on:submit.prevent="updatePlaylist" class="grid grid-cols-1 gap-3">
        <div>
          <label for="title" class="font-semibold">Tiêu đề (*)</label>
          <input v-model="playlist.title" class="shadow-none with-border" id="title" type="text" autocomplete="off"
                 required>
        </div>
        <div>
          <label for="title" class="font-semibold">Hình ảnh minh họa</label>
          <div class="mt-4">
            <DropZone type="image" :imageProp="playlist.thumbnail ? playlist.thumbnail.url : null" v-model:objectIdProp="playlist.image_id"/>
          </div>
        </div>
        <div v-if="playlist.items.data && playlist.items.data.length" class="mt-4">
          <div class="flex justify-end mb-3">
            <a href="javascript:void(0)" v-on:click="sortByName"
               class="mr-2 relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
              Sắp xếp theo ABC
            </a>
            <a href="javascript:void(0)" v-on:click="sortByRandom"
               class="relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
              Sắp xếp ngẫu nhiên
            </a>
          </div>
          <draggable
              v-model="playlist.items.data"
              group="people"
              @start="drag=true"
              @end="drag=false"
              item-key="id">
            <template #item="{element}">
              <div class="flex items-center justify-between card px-3 mb-3" style="cursor: move">
                <song-item :song="element.object" prefix="bai-hat"/>
                <div>
                  <a href="javascript:void(0)" v-on:click="removeItem(element.id)"
                     class="relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <i class="uil-trash-alt mr-1"></i> Xóa
                  </a>
                </div>
              </div>
            </template>
          </draggable>
        </div>
        <div class="border-t flex justify-center border-gray-100 p-6">
          <button type="submit" :disabled="disabled" class="button lg:w-1/3 hover:bg-blue-800">Cập nhật</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import Alert from "../Alert";
import draggable from 'vuedraggable'
import SongItem from "../SongItem";
import DropZone from "@/components/DropZone.vue";

export default {
  name: "PlaylistEdit",
  components: {Alert, draggable, SongItem, DropZone},
  data() {
    return {
      id: null,
      playlist: {
        title: "",
        image_id: null,
        thumbnail: {
          url: null
        },
        items: {
          data: []
        }
      },
      delete: [],
      status: "",
      message: "",
      disabled: false
    }
  },
  methods: {
    loadPlaylist() {
      let query = `query($id: ID!) {
        playlist(id: $id) {
          id
          title
          slug
          image_id
          thumbnail {
            url
          }
          items(first: 1000, orderBy: [{column: "position", order: ASC}]) {
            data {
              id
              type
              position
              song_id
              folk_id
              instrumental_id
              karaoke_id
              poem_id
              object {
                __typename
                id
                title
                slug
                thumbnail {
                  url
                }
                file {
                  server
                  audio
                  video
                }
                ... on Song {
                  artists(first: 10) {
                    data {
                      title
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }`;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.playlist) {
              this.playlist = data.data.playlist;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    updatePlaylist() {
      this.message = "";
      this.disabled = true;

      let query = `mutation($id: ID!, $title: String, $items: CRUDPlaylistItem) {
        updatePlaylist(input: {
          id: $id,
          title: $title,
          items: $items
        }) {
          id
        }
      }`;

      let items = {
        update: [],
        delete: this.delete
      };

      if (this.playlist.items.data && this.playlist.items.data.length) {
        for (let i = 0; i < this.playlist.items.data.length; i++) {
          let item = {
            id: this.playlist.items.data[i].id,
            type: this.playlist.items.data[i].type,
            object_id: this.playlist.items.data[i].object_id,
            position: i,
            song_id: this.playlist.items.data[i].song_id,
            folk_id: this.playlist.items.data[i].folk_id,
            instrumental_id: this.playlist.items.data[i].instrumental_id,
            karaoke_id: this.playlist.items.data[i].karaoke_id,
            poem_id: this.playlist.items.data[i].poem_id
          }
          items.update.push(item);
        }
      }

      ApiService.graphql(query, {
        id: this.playlist.id,
        title: this.playlist.title,
        image_id: this.playlist.image_id,
        items: items
      })
          .then(({data}) => {
            if (data.data && data.data.updatePlaylist) {
              this.status = "success";
              this.message = `Cập nhật thành công. Xem playlist <a class="font-semibold" href="/playlist/${this.playlist.slug}-${this.playlist.id}.html">tại đây</a>`;
              window.scrollTo(0, 0);
              this.disabled = false;
            } else {
              this.status = "error";
              this.message = data.errors[0].message;
              window.scrollTo(0, 0);
              this.disabled = false;
            }
          })
          .catch((response) => {
            this.status = "error";
            this.message = response.message;
            window.scrollTo(0, 0);
            this.disabled = false;
          });
    },
    removeItem(id) {
      this.delete.push(id);
      for (let i = 0; i < this.playlist.items.data.length; i++) {
        console.log(this.playlist.items.data[i].id, id);
        if (this.playlist.items.data[i].id === id) {
          this.playlist.items.data.splice(i, 1);
        }
      }
    },
    sortByName() {
      this.playlist.items.data.sort(this.compareName);
    },
    sortByRandom() {
      let array = this.playlist.items.data;
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
    },
    compareName(a, b) {
      if (a.object.title < b.object.title) {
        return -1;
      }
      if (a.object.title > b.object.title) {
        return 1;
      }
      return 0;
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.loadPlaylist();
  }
}
</script>
